<ion-content class="device-padding">
  <div class="container">
    <div class="ion-padding-horizontal">
      <h6>Go premium to continue your journey...</h6>

      <div
        class="question-container ion-padding-horizontal"
        *ngIf="activeSlide$ | async as slide"
      >
        <div class="question">
          {{ slide.title }}
        </div>
        <div class="description">
          {{ slide.text }}
        </div>
      </div>
    </div>
    <div class="slides ion-padding-vertical">
      <swiper-container
        slides-per-view="1.3"
        space-between="50"
        effect="coverflow"
        pagination="true"
        centered-slides="true"
        coverflow-effect-rotate="5"
        coverflow-effect-stretch="20"
        coverflow-effect-depth="100"
        coverflow-effect-slide-shadows="false"
        class="mySwiper"
        #slider
      >
        <swiper-slide *ngFor="let slide of slides; trackBy: trackBySlide">
          <div class="slide">
            <ion-img class="image" [src]="slide?.image"></ion-img>
            <div class="bullets">
              <div class="bullet" *ngFor="let bullet of slide?.bullets">
                <div class="title" *ngIf="bullet.title">{{ bullet.title }}</div>
                <div class="description" *ngIf="bullet.text">
                  {{ bullet.text }}
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper-container>
    </div>

    <section class="ion-padding-horizontal">
      <ion-grid>
        <ion-col>
          <span (click)="logout()">
            <ion-icon name="chevron-back-outline"></ion-icon>
            <span>Logout</span>
          </span>
        </ion-col>

        <ion-col (click)="next()"
          ><span>Subscribe</span>
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </ion-col>
      </ion-grid>
    </section>
  </div>
</ion-content>
