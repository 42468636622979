import { environment } from '../../environments/environment';
import {
  MediaFile as VideoEditorMediaFile,
  MediaFileResult,
  VideoEditor,
} from '@whiteguru/capacitor-plugin-video-editor';
import { PickedFile } from '../../../capacitor/media-picker';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Transfer, TransferType } from '../interfaces';
import { resizeBase64Image } from '../pages/camera/manipulation';
import { MediaFile } from '@awesome-cordova-plugins/media-capture/ngx';
import { createTransfer } from '../store/states/functions';
import { UploadJsonApiResponse } from '../services/upload/types';

export * from './utm/utm';

export function mySQLFormattedDate(newDate: Date | string = null) {
  if (typeof newDate === 'string' && /\d{4}-\d{2}-\d{2}/.exec(newDate)) {
    return newDate;
  }

  if (!(newDate instanceof Date)) {
    newDate = new Date();
  }

  const year = newDate.getFullYear();
  const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
  const day = newDate.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export function apiUrl(endpoint: string, version = null) {
  const baseUrl = version
    ? environment.apiUrl.replace(/\/(v[\d\.]+)\/$/, `/${version}/`)
    : environment.apiUrl;
  return `${baseUrl}${endpoint}`;
}

export function jsonApiUrl(endpoint = '', version = 'v3') {
  const baseUrl = version
    ? environment.jsonApiUrl.replace(/\/(v[\d\.]+)\/$/, `/${version}/`)
    : environment.jsonApiUrl;
  return `${baseUrl}${endpoint}`;
}

export function filterEmptyValues(params: object): object {
  return Object.keys(params)
    .map(k => [k, params[k]])
    .filter(i => i[1] !== null && i[1] !== '')
    .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});
}

export function generateHttpOptions(params: object): string {
  const options = Object.entries(filterEmptyValues(params))
    .map(([k, v]) => escape(k) + '=' + escape(v))
    .join('&');

  return options.length > 1 ? `?${options}` : '';
}

export function convertLoggedOn(o) {
  o.logged_on = new Date(o.logged_on);
  return o;
}

export function createIndexedMap<T extends object>(
  source: T[],
  key: string,
): { [key: string]: T } {
  return source.reduce((a, c) => ({ ...a, [c[key]]: c }), {});
}

export function createSortIndex(source, sortMethod, key: string): number[] {
  return source.sort(sortMethod).reduce((a, c) => [...a, c[key]], []);
}

export function filterIndexedMap<T extends object>(
  indexedMap,
  predicate: (o: T) => boolean,
) {
  return Object.keys(indexedMap)
    .filter(key => predicate(indexedMap[key]))
    .reduce((a, c) => (a[c] = indexedMap[c]), {});
}

export const opt = (
  order: number,
  title: string,
  value?,
  description?: string,
  icon = '',
  isPremium = false,
) => {
  if (!value) {
    value = title;
  }
  return { value, title, description, order, icon: icon, isPremium };
};

export function isVideo(mime) {
  return !mime.match('image.*');
}

export function getFileNameFromUri(fileUri) {
  return fileUri.replace(/^.*[\\\/]/, '');
}

export function getMimeTypeFromFileUri(uri: string) {
  return getMimeTypeFromExtension(uri.split('?')[0].split('.').pop());
}

export function getMimeTypeFromExtension(extension) {
  switch (extension.toLowerCase()) {
    // Image
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg';
    case 'heic':
    case 'heif':
      return 'image/heic';
    case 'png':
      return 'image/png';
    // Video
    case '3gp':
      return 'video/3gpp';
    case 'm1v':
    case 'm2v':
    case 'mpg':
    case 'mpe':
    case 'm4a':
    case 'mpeg':
      return 'video/mpeg';
    case 'mov':
    case 'moov':
    case 'movie':
    case 'qt':
    case 'qtc':
      return 'video/quicktime';
    case 'mp4':
    case 'm4v':
      return 'video/mp4';
    case 'ogv':
      return 'video/ogg';
    case 'webm':
      return 'video/webm';
    case 'avi':
      return 'video/x-msvideo';
    default:
      return null;
  }
}

export const isMediaFile = (a): a is MediaFile => {
  return (
    a &&
    a.hasOwnProperty('fullPath') &&
    a.hasOwnProperty('name') &&
    a.hasOwnProperty('type')
  );
};

export const isVideoEditorMediaFile = (a): a is VideoEditorMediaFile => {
  return (
    a &&
    a.hasOwnProperty('path') &&
    a.hasOwnProperty('name') &&
    a.hasOwnProperty('size') &&
    a.hasOwnProperty('type')
  );
};

export type FileUploadInfo = { mimeType: string; path?: string; name: string };

export function generateTransferFileUpload(
  file:
    | Pick<PickedFile, 'path' | 'mimeType'>
    | VideoEditorMediaFile
    | File
    | string,
): FileUploadInfo {
  if (file instanceof File) {
    return {
      name: file.name,
      mimeType: file.type,
    };
  }

  if (isVideoEditorMediaFile(file)) {
    return { ...file, mimeType: file.type };
  } else if (typeof file === 'string') {
    // File URI
    return {
      name: getFileNameFromUri(file),
      mimeType: getMimeTypeFromFileUri(file as string),
      path: file,
    };
  } else {
    return {
      name: getFileNameFromUri(file.path),
      mimeType: file.mimeType,
      path: file.path,
    };
  }
}

export type AnnouncementContext = {
  announcementUuid: string;
  upload?: UploadJsonApiResponse;
};

export function createAnnouncementTransfer<T>(
  file: FileUploadInfo,
  context: T,
): Transfer<T> {
  return {
    ...createTransfer(TransferType.Announcement, file),
    mimeType: file.mimeType,
    context,
  };
}

export async function generateThumbnail(
  attachmentType: string,
  file: Pick<PickedFile, 'path' | 'mimeType'>,
): Promise<
  | MediaFileResult
  | {
      file: string;
      name: string;
    }
> {
  let thumbnail: MediaFileResult | { file: string; name: string };

  switch (attachmentType) {
    case 'video':
      thumbnail = await VideoEditor.thumbnail({
        path: file.path,
        at: 0.001,
      });
      break;
    case 'image':
      thumbnail = {
        file: await compressMobileImage(file.path),
        name: getFileNameFromUri(file.path),
      };
      break;
    default:
      // catch invalid calls to this function.
      throw new Error(`Invalid attachment type: ${attachmentType}.`);
  }

  return thumbnail;
}

export function getTypeFromMimeType(
  mimeType: string,
): 'image' | 'video' | 'file' {
  switch (mimeType.split('/')[0]) {
    case 'image':
      return 'image';
    case 'video':
      return 'video';
    default:
      return 'file';
  }
}

export async function compressMobileImage(fileUrl: string): Promise<string> {
  const contents = await Filesystem.readFile({
    path: fileUrl,
  });

  let fileName = getFileNameFromUri(fileUrl);
  fileName = fileName.split('.');
  fileName = fileName[fileName.length - 2] + '.jpeg';

  return new Promise(resolve =>
    resizeBase64Image(
      'data:image/jpeg;base64,' + contents.data,
      imageData => {
        Filesystem.writeFile({
          path: fileName,
          directory: Directory.Cache,
          data: imageData,
        }).then(res => resolve(res.uri));
      },
      500,
    ),
  );
}

export function eventLooksLikePastingLink($event: ClipboardEvent) {
  if (
    $event.clipboardData.items.length === 1 &&
    $event.clipboardData.getData('text/plain') !== '' &&
    $event.clipboardData.getData('text/uri-list') !== ''
  ) {
    return true;
  }
  return false;
}

export function retrievePastedText($event: ClipboardEvent): null | string {
  if (!eventLooksLikePastingLink($event)) {
    return null;
  }

  // We are supposed to do this, per MDN.
  $event.preventDefault();

  // Take the string elements of the clipboard event and paste them into the chat.
  const clipboardData = $event.clipboardData;

  // Take the information in the clipboard prepending the text component to the
  // url.
  let pastedText = clipboardData.getData('text/plain');
  pastedText += ' ' + clipboardData.getData('text/uri-list');

  return pastedText;
}
