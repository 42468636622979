import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RollbarService } from '../../rollbar';
import * as Rollbar from 'rollbar';
import { UserService } from '../user/user.service';
import { apiUrl } from '../../helpers';
import { tap } from 'rxjs/operators';

export class UploadProfilePictureResult {
  profile_picture_url: string;
}

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(
    protected http: HttpClient,
    protected userService: UserService,
    @Inject(RollbarService) public rollbar: Rollbar,
  ) {}

  public changePassword(
    old_password: string,
    password: string,
    password_confirmation: string,
  ): Observable<unknown> {
    const data = {
      password,
      old_password,
      password_confirmation,
    };
    return this.http.post(apiUrl('change-password'), data);
  }

  /**
   * Uploads a picture profile
   * @param profilePictureBase64Data the image on base64 format on image/jpeg format
   */
  public uploadProfilePicture(
    profilePictureBase64Data: string,
  ): Observable<UploadProfilePictureResult> {
    return this.http
      .post<UploadProfilePictureResult>(apiUrl('set-profile-picture'), {
        profile_picture: profilePictureBase64Data,
      })
      .pipe(
        // Update the user profile to include the new, correct URL.
        tap(({ profile_picture_url }) => {
          this.userService.user = {
            ...this.userService.user,
            profile_picture_url: profile_picture_url,
          };
        }),
      );
  }
}
